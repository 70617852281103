<template>
	<cookie-law position="bottom" transition-name="fade">
		<div
			slot-scope="props"
			class="wrapper my-0 is-flex is-align-items-center is-justify-content-space-between is-flex-wrap-wrap"
		>
			<p>
				Acest site web folosește cookie-uri pentru a vă asigura că
				obțineți cea mai bună experiență pe site-ul nostru.
			</p>
			<div class="is-flex buttons">
				<button class="btn btn--primary mr-2" @click="props.accept">
					<span>Accept</span>
				</button>
				<button class="btn btn--danger" @click="props.close">
					<span>Anulare</span>
				</button>
			</div>
		</div>
	</cookie-law>
</template>

<script>
	import CookieLaw from 'vue-cookie-law';

	export default {
		components: {
			CookieLaw,
		},
	};
</script>

<style lang="scss" scoped>
	.wrapper {
		width: 100%;
	}
	p {
		font-weight: $medium;
		text-align: center;
		margin: 0 0 1rem 0;
		width: 100%;
		@media (min-width: 1055px) {
			margin: 0 1rem 0 0;
			width: auto;
		}
	}
	.buttons {
		margin: 0 auto;
		@media (min-width: 1055px) {
			margin: 0;
		}
	}
</style>
