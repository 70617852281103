import axios from 'axios';

const state = {
  categories: {},
  category: null
};

const mutations = {
  SET_CATEGORIES(state, payload) {
    state.categories = payload;
  },
  SET_CATEGORY(state, payload) {
    state.category = payload;
  },
};

const actions = {
  async getCategories({ commit }) {
    const response = await axios.get(
      `${process.env.VUE_APP_STRAPI_DATABASE_URL}/categories`
    );
    const categories = await response.data;
    commit('SET_CATEGORIES', categories);
  },
  async getCategoryByName({ commit }, name) {

    const lang = localStorage.getItem('lang');
    const location = localStorage.getItem('location');
    let response, category;
    if(location === "RO" &&
		(location === 'RO' && lang === null) ||
		(location !== 'RO' && lang === null) ||
		(location === 'RO' && (lang !== null && lang === 'RO')) ||
		(location !== 'RO' && (lang !== null && lang === 'RO'))) {
      response = await axios.get(
        `${process.env.VUE_APP_STRAPI_DATABASE_URL}/categories/?nume=${name}`
      );
      category = await response.data[0];
    } else if(location !== "RO" &&
		(location !== 'RO' && lang === null) ||
		(location === 'RO' && (lang !== null && lang === 'EN')) ||
		(location !== 'RO' && (lang !== null && lang === 'RO'))) {
        response = await axios.get(
        `${process.env.VUE_APP_STRAPI_DATABASE_URL}/categories/?_locale=en&nume=${name}`
      );
      category = await response.data[0];
    }
  
    commit('SET_CATEGORY', category);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
